
import React, { useEffect, useState } from "react";
import { Link, redirect, useParams } from "react-router-dom";
import moment from 'moment';
import LayoutComponent from "../../components/layout.component";
import "react-datepicker/dist/react-datepicker.css";
import { vi } from 'date-fns/locale/vi';
import CreateOrderModel from "../../models/order/create-order.model";
import { Zoom, toast } from "react-toastify";
import JsonClientMessageStatuses from "../../commons/enums/enum.json-client-message";
import { usePromiseTracker } from 'react-promise-tracker';
// https://fkhadra.github.io/react-toastify/introduction/
import { useNavigate } from 'react-router-dom';
import productHttpService from "../../http-services/product-http-service";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from "react-image-gallery";
import CustomSlider from './../../components/custom.slider';
import BuyButtonComponent from "../../components/buy-now.button";
import { ProductItemModel } from "../../models/product/item.product";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { getCartItems } from "../../reducers/cart.reducer";
import ProductCardComponent from "../../components/product.cart";

function ProductComponent() {
    const navigate = useNavigate();
    const { promiseInProgress } = usePromiseTracker();

    const products = useSelector(getCartItems);

    const { categoryId, categoryName, productId, productName } = useParams();
    const [product, setProduct] = useState<ProductItemModel>();
    const [quantity, setQuantity] = useState<number>(1);

    useEffect(() => {
        const getProductInfoById = async (productId: string) => {
            let jsonInfo = await productHttpService.getProductById(productId);

            if (jsonInfo.status === JsonClientMessageStatuses.Success) {
                let product = { ...jsonInfo.data, quantity: 1 };
                setProduct(product);
            }

            console.log(jsonInfo);
        }
        if (productId) {
            getProductInfoById(productId);
        }
    }, [productId]);

    useEffect(() => {
        console.log(products);
    }, [products]);

    const onChangeQuantity = (isPlus: boolean): void => {
        if (isPlus) {
            let value = quantity + 1;
            if (value > 999)
                value = 999;
            setQuantity(value);
        } else {
            let value = quantity - 1;
            if (value < 1)
                value = 1;

            setQuantity(value);
        }
    }

    const images = [
        {
            original: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            thumbnail: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            isVideo: false
        },
        {
            original: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            thumbnail: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            isVideo: false
        },
        {
            original: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            thumbnail: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            isVideo: false
        },
        {
            original: "https://www.youtube.com/embed/4pSzhZ76GdM?autoplay=1&showinfo=0",
            thumbnail: "https://www.bootdey.com/image/550x350/FFB6C1/000000",
            isVideo: true,
            embedUrl: 'https://www.youtube.com/embed/sGvh-WJjK60?autoplay=1&showinfo=0'
        },
    ];


    return (<LayoutComponent>
        <div className="container product-container" id="product-container">
            <div className="row">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
                        <li className="breadcrumb-item"><Link to={`/danh-muc-san-pham`}>Danh mục sản phẩm</Link></li>
                        <li className="breadcrumb-item"><Link to={`/${product?.categoryUrl}`}>{product?.categoryName}</Link></li>
                        <li className="breadcrumb-item"><Link to={`/${product?.friendlyUrl}`}>{product?.productName}</Link></li>
                    </ol>
                </nav>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <CustomSlider assets={[1, 2, 3, 4, 5]} />
                </div>

                <div className="col-md-6 col-sm-12 product-content-container">
                    <h3>{product?.productName}</h3>

                    <p><span>Mã sản phẩm: {product?.code}</span></p>

                    <div className="product-price">
                        <h4>
                            {
                                product && product.discount > 0 &&
                                <>
                                    <NumericFormat className="new-price text-danger" value={product?.discount} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                                    <span> </span>
                                    <NumericFormat className="old-price" value={product?.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                                </>
                            }

                            {
                                product && product.discount === 0 &&
                                <>
                                    <NumericFormat className="new-price" value={product?.price} displayType="text" thousandSeparator="." decimalSeparator="," prefix="" suffix="₫" />
                                </>
                            }
                        </h4>
                    </div>

                    <div className="product-controls">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" onClick={() => onChangeQuantity(true)}>
                                    <i className="fa fa-plus"></i>
                                </span>
                            </div>
                            <input type="number" min={1} max={999} value={quantity} onChange={(event) => setQuantity(Number.parseInt(event.target.value))} className="form-control" />
                            <div className="input-group-append">
                                <span className="input-group-text" onClick={() => onChangeQuantity(false)}>
                                    <i className="fa fa-minus"></i>
                                </span>
                            </div>
                        </div>
                        <BuyButtonComponent id={product?.productId} product={product} quantity={quantity} />
                    </div>

                    <div className="product-group">
                        <p>Sản phẩm cùng loại:</p>

                        <Link to="/">
                            <img src="https://www.bootdey.com/image/550x350/FFB6C1/000000" />
                        </Link>

                        <Link to="/">
                            <img src="https://www.bootdey.com/image/550x350/FFB6C1/000000" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        {
            product && product.relatedProducts &&
            <section className="banner-bottom py-5" id="about">
                <div className="container py-lg-5">
                    <h2 className="heading mb-sm-5 mb-4"> Sản phẩm cùng danh mục</h2>
                    <div className="row bottom-grids">
                        {
                            product?.relatedProducts?.map(item =>
                                <ProductCardComponent key={`product-details-card-show-product-by-id-${item.productId}`} product={item} />
                            )
                        }
                    </div>
                </div>
            </section>
        }
    </LayoutComponent >);
}

export default ProductComponent;