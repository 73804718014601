
import React, { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import moment from 'moment';
import LayoutComponent from "../../components/layout.component";
import orderHttpService from "../../http-services/order-http-service";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { useForm, SubmitHandler } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { vi } from 'date-fns/locale/vi';
import CreateOrderModel from "../../models/order/create-order.model";
import { Zoom, toast } from "react-toastify";
import JsonClientMessageStatuses from "../../commons/enums/enum.json-client-message";
import { usePromiseTracker } from 'react-promise-tracker';
// https://fkhadra.github.io/react-toastify/introduction/
import { useNavigate } from 'react-router-dom';
import { EnumOrderPlaceTypes, EnumOrderType } from "../../commons/enums/enum.order";
function OrderComponent() {
    const navigate = useNavigate();
    const { promiseInProgress } = usePromiseTracker();
    const [selectedPlace, setSelectedPlace] = useState<number>(EnumOrderPlaceTypes.AtTuLinhBeauty);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const services = [
        { id: '38dcb227-76f8-44ee-a408-42227b6ff21a', name: 'Makeup', inService: [EnumOrderPlaceTypes.AtHome, EnumOrderPlaceTypes.AtTuLinhBeauty], },
        { id: '9d7d1c17-5d49-44ca-98d5-816f59882b69', name: 'Nails', inService: [EnumOrderPlaceTypes.AtHome, EnumOrderPlaceTypes.AtTuLinhBeauty], },
        { id: '8f320f5e-fec5-4b9e-9b38-bcee83027c8d', name: 'Gội Đầu', inService: [EnumOrderPlaceTypes.AtTuLinhBeauty], },
    ];

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
        reset
    } = useForm<CreateOrderModel>({
        defaultValues: {
            serviceTypes: [],
            orderType: EnumOrderType.Service,
            placeType: EnumOrderPlaceTypes.AtTuLinhBeauty
        }
    })

    useEffect(() => {
        if (selectedDate) {
            setValue('appointmentDate8601', selectedDate);
        }
    }, [selectedDate]);

    useEffect(() => {
        var formContainer = document.querySelector("#order-form");
        if (formContainer) {
            var xy = formContainer.getBoundingClientRect();
            setTimeout(() => {
                window.scroll({ top: xy.top + 150, left: 0, behavior: 'smooth' });
            }, 500);
        }
    }, [])

    const onSubmit = async (data: CreateOrderModel) => {
        var formData = { ...data, appointmentDate: moment(selectedDate).format('yyyy-MM-DD HH:mm:00') };
        delete formData.appointmentDate8601;

        let result = await orderHttpService.createOrder(formData);
        if (result.status === JsonClientMessageStatuses.Success) {
            toast.success(`Cảm ơn khách yêu đã tin tưởng sử dụng dịch vụ. 
            Lịch hẹn ${result.data} của bạn đã được tiếp nhận. 
            Tú Linh Beauty kính chúc khách yêu một ngày tràn đầy năng lượng, vui vẻ và hạnh phúc!`,
                { autoClose: 20000, transition: Zoom });
            reset();
            setSelectedDate(null);
        } else {
            toast.warning(`Opps không thể đặt đơn vào lúc này, khách yêu vui lòng thử lại sau nhé! ${result.message}`,
                { transition: Zoom });
        }
    }

    return (<LayoutComponent>
        <div id="order-form" className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 offset-sm-0 px-lg-3 px-0">
            <div className="padding order-form-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" {...register("orderType")} defaultValue={EnumOrderType.Service} />
                    <h5 className="mb-3">Đặt lịch làm dịch vụ</h5>
                    <div className="form-style-w3layout">
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-name">Tên của bạn (bắt buộc)</label>
                                {errors.customerName && <span className="custom-error text-danger">Đây là trường bắt buộc nên bạn cần nhập tên vào ạ!</span>}
                            </div>
                            <div className="col-md-12">
                                <input {...register("customerName", { required: true })} id="order-name" type="text" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-phoneNumber">Số điện thoại của bạn (bắt buộc)</label>
                                {errors.customerPhone && <span className="custom-error text-danger">Đây là trường bắt buộc nên bạn cần nhập số điện thoại vào ạ!</span>}
                            </div>
                            <div className="col-md-12">
                                <input {...register("customerPhone", { required: true, pattern: /^[0]{1}[1-9]{1}[0-9]{8,9}$/i })} id="order-phoneNumber" type="text" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-email">Email của bạn (không bắt buộc)</label>
                            </div>
                            <div className="col-md-12">
                                <input {...register("customerEmail")} id="order-email" type="email" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-date">Ngày hẹn (không bắt buộc)</label>
                            </div>
                            <div className="col-md-12">
                                <DatePicker showTimeSelect
                                    id="order-date"
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="time"
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    locale={vi}
                                    selected={selectedDate}
                                    minDate={new Date()}
                                    showTimeSelectOnly={false}
                                    clearButtonTitle="Xoá"
                                    isClearable={true}
                                    onKeyDown={(e) => { e.preventDefault() }}
                                    onChange={(date: Date) => { console.log(date); setSelectedDate(date); }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-email">Chọn nơi làm dịch vụ (bắt buộc)</label>
                            </div>
                            <div className="col-md-12">
                                <select  {...register("placeType")} onChange={(event) => setSelectedPlace(Number.parseInt(event.target.value))}>
                                    <option value="1">Tú Linh Beauty</option>
                                    <option value="2">Tại nhà</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="separator" />
                            </div>
                            <div className="col-md-12">Chọn dịch vụ (không bắt buộc)</div>
                            <div className="col-md-12">
                                <div className="row">
                                    {
                                        services.filter(s => s.inService.some(is => is === selectedPlace)).map(s =>
                                            <div className="col-md-4" key={`form-service-wrapper-${s.id}`}>
                                                <label>{s.name} <input {...register("serviceTypes")} type="checkbox" value={s.id} /></label>
                                            </div>
                                        )
                                    }


                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="separator" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-address">Địa chỉ của bạn (không bắt buộc)</label>
                            </div>
                            <div className="col-md-12">
                                <input {...register("customerAddress")} id="order-address" type="text" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-note">Ghi chú(không bắt buộc)</label>
                            </div>
                            <div className="col-md-12">
                                <textarea {...register("note")} id="order-note"></textarea>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="order-coupon">Mã giảm giá (nếu có)</label>
                            </div>
                            <div className="col-md-12">
                                <input {...register("couponCode")} id="order-coupon" type="text" />
                            </div>
                        </div>
                        {!promiseInProgress && <button className="btn">Hoàn tất <i className="fa fa-send"></i></button>}
                        {promiseInProgress && <button className="btn">Đang gửi... <i className="fa fa-spinner fa-spin"></i></button>}
                    </div>
                </form>
            </div>
        </div >
    </LayoutComponent >);
}

export default OrderComponent;