import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ProductItemModel } from "../models/product/item.product";
import { addCartItem } from "../reducers/cart.reducer";

interface BuyButtonProps {
    id?: string;
    product?: ProductItemModel;
    quantity?: number;
}

const BuyButtonComponent = (props: BuyButtonProps) => {

    const eventIsAdded = useRef(false);
    const [product, setProduct] = useState<ProductItemModel>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.id && !eventIsAdded.current) {
            console.log('add: ', props.id)
            document.querySelectorAll(`#button-id-${props.id}`).forEach(button => button.addEventListener('click', e => {
                if (!button.classList.contains('loading')) {
                    button.classList.add('loading');
                    setTimeout(() => button.classList.remove('loading'), 1000);
                }
                e.preventDefault();
            }));
        }

        if (props.product) {
            setProduct({ ...props.product, quantity: props.quantity ?? 1 });
        }

    }, [props]);

    return <button className="button btn-buy" id={`button-id-${props.id}`} onClick={() => dispatch(addCartItem(product!))}>
        <span>Mua Ngay</span>
        <div className="cart">
            <svg viewBox="0 0 36 26">
                <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
            </svg>
        </div>
    </button>
}

export default BuyButtonComponent;