import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function HeaderComponent() {
    const phoneNumber = process.env.REACT_APP_PHONE_NUMBER_OWNER;
    const email = process.env.REACT_APP_EMAIL_OWNER;
    const address = process.env.REACT_APP_ADDRESS_OWNER;
    const maps = process.env.REACT_APP_GOOGLE_MAPS_LINK;

    const navigate = useNavigate();

    const orderNow = () => {
        return navigate("/dat-lich-hen");
    }

    const goToSection = (id: string) => {
        var formContainer = document.querySelector(`#${id}`);
        if (formContainer) {
            var xy = formContainer.getBoundingClientRect();
            setTimeout(() => {
                window.scroll({ top: xy.top, left: 0, behavior: 'smooth' });
            }, 500);
        }
    }

    return (
        <>
            <header>
                <div className="top-head container">
                    <div className="ml-auto text-right right-p">
                        <ul>
                            <li className="mr-3">
                                <a href={`tel:${phoneNumber}`}><span className="fa fa-phone"></span> {phoneNumber}</a></li>
                            <li>
                                <a href={`mailto:${email}`}><span className="fa fa-envelope-open"></span> {email}</a> </li>
                        </ul>
                    </div>
                </div>
                <div className="container">
                    <nav className="py-3 d-lg-flex">
                        <div id="logo">
                            <h1> <Link to="/"><span className="fa fa-home"></span></Link></h1>
                        </div>
                        <label htmlFor="drop" className="toggle"><span className="fa fa-bars"></span></label>
                        <input type="checkbox" id="drop" />
                        <ul className="menu ml-auto mt-1">
                            <li className="active"><Link to="/">Trang Chủ</Link></li>
                            <li className="active"><Link to="/danh-muc-san-pham">Danh mục sản phẩm</Link></li>
                            <li className="active"><Link to="/" onClick={()=> goToSection('services')}>Dịch vụ</Link></li>
                            <li className="active"><Link to="/" onClick={()=> goToSection('stats')}>Thư viện</Link></li>
                            <li className="active"><Link to="/" onClick={()=> goToSection('subscribe')}>Đăng ký</Link></li>
                        </ul>
                    </nav>
                </div>
            </header>

            <div className="banner" id="home">
                <div className="slider">
                    <div className="slide"></div>
                    <div className="slide"></div>
                    <div className="slide"></div>
                    <div className="slide"></div>
                    <div className="slide"></div>
                    <div className="slide"></div>
                </div>
                <div className="layer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 banner-text-w3ls">
                                <div className="csslider infinity" id="slider1">
                                    <ul className="banner_slide_bg">
                                        <li>
                                            <div className="container-fluid">
                                                <div className="w3ls_banner_txt">
                                                    <h3 className="b-w3ltxt text-capitalize mt-md-4">Tú Linh Beauty</h3>
                                                    <h4 className="b-w3ltxt text-capitalize mt-md-2">Makeup - Nails - Gội Đầu</h4>
                                                    <p className="w3ls_pvt-title my-3">
                                                        <a href={maps} target="_blank"><span className="fa fa-map"></span> {address}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-8 px-lg-3 px-0">
                                <div className="banner-form-w3 ml-lg-5">
                                    <div className="padding">
                                        <button onClick={() => orderNow()} className="btn btn-order"><i className="fa fa-calendar"></i> Đặt lịch ngay!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderComponent;