import { configureStore } from "@reduxjs/toolkit";
import cartReducer, { reloadCartItems } from './reducers/cart.reducer';

export const store = configureStore({
    reducer: {
        cart: cartReducer
    }
});

const reloadData = () => {
    return (dispatch, getState) => {
        dispatch(reloadCartItems(undefined));
    }
}

store.dispatch(reloadData());

export type RootState = ReturnType<typeof store.getState>