import React, { ReactNode } from "react";
import HeaderComponent from "./header.component";
import FooterComponent from "./footer.component";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface LayoutProps {
    children: ReactNode;
}

function LayoutComponent(props: LayoutProps) {
    return (
        <>
            <HeaderComponent />
            {props.children}
            <FooterComponent />

            <ToastContainer />
        </>
    );
}

export default LayoutComponent;