import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ConstLocalStorageKey from '../commons/consts/const-local-storage';
import { ProductItemModel } from '../models/product/item.product';
import CartModel from '../models/order/cart.model';
import { RootState } from '../store';

const initialState: CartModel = {
    cartItems: [],
    shippingCost: 0,
    subTotal: 0,
    total: 0
};

const cartSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        addCartItem: (state, action: PayloadAction<ProductItemModel>) => {
            let product = action.payload;
            let cartItem = state.cartItems.find(x => x.productId === product.productId);
            if (cartItem !== undefined && cartItem !== null) {
                cartItem.quantity = cartItem.quantity + product.quantity;
            } else {
                state.cartItems.push(product);
            }
            // https://www.npmjs.com/package/react-toastify
            toast(`Sản phẩm '${product.productName}' đã được thêm vào giỏ hàng!`, {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                type: 'success',
                onClick: (event) => {

                }
            });
            localStorage.setItem(ConstLocalStorageKey.CART, JSON.stringify(state.cartItems));
        },
        updateCardItem: (state, action: PayloadAction<ProductItemModel>) => {
            if (action.payload) {
                let item = action.payload;
                if (item.quantity > 100 || item.quantity === 0) {
                    return;
                } else {

                    let items = state.cartItems;

                    for (let index = 0; index < state.cartItems.length; index++) {
                        if (items[index].productId === item.productId) {
                            items[index] = item;
                        }
                    }

                    state.cartItems = [...items];
                    localStorage.setItem(ConstLocalStorageKey.CART, JSON.stringify(state.cartItems));
                }
            }
        },
        deleteCartItem: (state, action: PayloadAction<ProductItemModel>) => {
            let product = action.payload;
            let items = Object.values(state.cartItems).filter(x => x.productId !== product.productId);
            state.cartItems = items;
            // https://www.npmjs.com/package/react-toastify
            toast(`Sản phẩm '${product.productName}' đã bị xoá khỏi giỏ hàng!`, {
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                type: 'warning'
            });
            localStorage.setItem(ConstLocalStorageKey.CART, JSON.stringify(state.cartItems));
        },
        reloadCartItems: (state, action) => {
            try {
                let jsonString = localStorage.getItem(ConstLocalStorageKey.CART);
                let jsonData = JSON.parse(jsonString!);
                if (jsonData !== undefined && jsonData !== null) {
                    state.cartItems = jsonData;
                }
            } catch (error) {
                console.log('reload card items: ', error);
            }
        },
        clearCartItems: (state, action: PayloadAction<ProductItemModel>) => {
            state.cartItems = [];
            localStorage.removeItem(ConstLocalStorageKey.CART);
        }
    }
});

export const { addCartItem, updateCardItem, deleteCartItem, reloadCartItems, clearCartItems } = cartSlice.actions;
export const getCartItems = (state: RootState) => state.cart.cartItems;

export default cartSlice.reducer;