import JsonClientMessage from "../models/http/json-client-message.order";
import { ProductItemModel } from "../models/product/item.product";
import { ProductCategortModel } from "../models/product/product-category";
import { http } from "./http"

const getLatestProducts = async (): Promise<JsonClientMessage<ProductItemModel[]>> => {
    return http.get('product/get-latest-products');
}

const getProductsByCategoryId = async (categoryId: string): Promise<JsonClientMessage<ProductCategortModel>> => {
    return http.get(`product/get-products-by-category-id/${categoryId}`);
}

const getProductById = async (productId: string): Promise<JsonClientMessage<ProductItemModel>> => {
    return http.get(`product/get-product-by-id/${productId}`);
}


const productHttpService = {
    getLatestProducts,
    getProductById,
    getProductsByCategoryId
};

export default productHttpService;