import JsonClientMessage from "../models/http/json-client-message.order";
import CreateOrderModel from "../models/order/create-order.model";
import { http } from "./http"

const createOrder = async (model: CreateOrderModel): Promise<JsonClientMessage<string>> => {
    return http.post('order/create-order', model);
}

const orderHttpService = {
    createOrder
};

export default orderHttpService;