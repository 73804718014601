import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import ConstLocalStorageKey from '../commons/consts/const-local-storage';
import JsonClientMessage from '../models/http/json-client-message.order';


const httpProvider = axios.create({
  // The URL will be changed in different environments.
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    transactionId: `${new Date().getTime()}`,
  },
  timeout: 30000,
});

httpProvider.interceptors.request.use(
  async config => {
    const token = getToken();
    if (token) {
      console.log('test.....')
      config.headers.Authorization = `Bearer ${token}`;
    }
    const usedTimePreviousAction = 5000;
    if (usedTimePreviousAction) {
      config.headers['used-time'] = usedTimePreviousAction;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

httpProvider.interceptors.response.use(
  async response => {
    if (response.status === 200) {
      return response;
    }
    return response;
  },
  error => {
    return Promise.reject(error?.response);
  },
);

const getToken = () => {
  return localStorage.getItem(ConstLocalStorageKey.TOKEN_KEY);
};

const get = async (url:string): Promise<JsonClientMessage<any>> => {
  try {
    let result = await trackPromise(httpProvider.get<JsonClientMessage<any>>(url));
    return result.data;
  } catch (err) {
    return await trackPromise(Promise.reject(err));
  }
};

const post = async (url: string, data: any) : Promise<JsonClientMessage<any>> => {
  try {
    let result = await trackPromise(httpProvider.post<JsonClientMessage<any>>(url, data));
    return result.data;
  } catch (err) {
    return await trackPromise(Promise.reject(err));
  }
};

const put = async (url: string, data: any): Promise<JsonClientMessage<any>> => {
  try {
    let result = await trackPromise(httpProvider.put<JsonClientMessage<any>>(url, data));
    return result.data;
  } catch (err) {
    return await trackPromise(Promise.reject(err));
  }
};

const del = async (url: string): Promise<JsonClientMessage<any>> => {
  try {
    let result = await trackPromise(httpProvider.delete<JsonClientMessage<any>>(url));
    return result.data;
  } catch (err) {
    return await trackPromise(Promise.reject(err));
  }
};

const postFile = async (url: string, data: FormData): Promise<JsonClientMessage<any>> => {
  try {
    let result = await trackPromise(
      httpProvider.post<JsonClientMessage<any>>(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }),
    );
    return result.data;
  } catch (err) {
    return await trackPromise(Promise.reject(err));
  }
};

export const http = {
  get: get,
  post: post,
  put: put,
  delete: del,
  postFile: postFile
};
