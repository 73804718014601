import { CategoryModel } from "../models/category/category.model";
import JsonClientMessage from "../models/http/json-client-message.order";
import { http } from "./http"

const getCategories = async (): Promise<JsonClientMessage<CategoryModel[]>> => {
    return http.get('category/get-categories');
}

const categoryHttpService = {
    getCategories
};

export default categoryHttpService;