import { useEffect, useState } from "react";
import LayoutComponent from "../../components/layout.component"
import { ProductItemModel } from '../../models/product/item.product';
import ProductCardComponent from "../../components/product.cart";
import productHttpService from "../../http-services/product-http-service";
import { Link, useParams } from "react-router-dom";
import { ProductCategortModel } from "../../models/product/product-category";

const CategoryComponent = () => {
    const [category, setCategory] = useState<ProductCategortModel>();
    const { categoryId, categoryName } = useParams();
    useEffect(() => {
        const loadProductListByCategoryId = async (categoryId: string) => {
            var jsonInfo = await productHttpService.getProductsByCategoryId(categoryId);
            if (jsonInfo.data) {
                setCategory(jsonInfo.data);
            }
        }

        if (categoryId) {
            loadProductListByCategoryId(categoryId);
        }

    }, [categoryId]);

    return <LayoutComponent>
        <section className="banner-bottom py-5" id="category">
            <div className="container menu-box">
                <div className="row">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Trang chủ</Link></li>
                            <li className="breadcrumb-item"><Link to={`/danh-muc-san-pham`}>Danh mục sản phẩm</Link></li>
                            <li className="breadcrumb-item"><Link to={`/${category?.categoryUrl}`}>{category?.name}</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container py-lg-5">
                {/* <h2 className="heading mb-sm-5 mb-4"> { category?.name }</h2> */}
                <div className="row bottom-grids">
                    {
                        category && category.products && category?.products?.map(item =>
                            <ProductCardComponent key={`card-show-product-by-id-${item.productId}`} product={item} />
                        )
                    }
                </div>
            </div>
        </section>
    </LayoutComponent>
}

export default CategoryComponent;