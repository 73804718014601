import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../../components/layout.component";
import { ProductItemModel } from "../../models/product/item.product";
import productHttpService from "../../http-services/product-http-service";
import { ImageModel } from "../../models/asset/image.asset.model";
import ProductCardComponent from "../../components/product.cart";

function HomeComponent() {
    const apiRoot = process.env.REACT_APP_API_ROOT;

    const [latestProducts, setLatestProducts] = useState<ProductItemModel[]>([]);

    const getImageUrl = (images: ImageModel[]): string => {
        if (images?.length > 0) {
            return `${apiRoot}${images.at(0)?.fileName}`;
        }

        return '';
    }

    useEffect(() => {
        const fetchData = async () => {
            let result = await productHttpService.getLatestProducts();

            if (result.data?.length > 0) {
                let products = [1, 2, 3, 4, 5, 6].map(item => result.data[0]);
                setLatestProducts(products);
            }
        }
        fetchData();
    }, []);

    return (
        <LayoutComponent>


            {/* What bla bla bla */}
            <section className="banner-bottom py-5">
                <div className="container py-lg-5">
                    <h2 className="heading mb-sm-5 mb-4"> Sản phẩm mới nhất</h2>
                    <div className="row bottom-grids">
                        {
                            latestProducts?.map(item =>
                                <ProductCardComponent key={`card-show-product-by-id-${item.productId}`} product={item} />
                            )
                        }
                    </div>
                </div>
            </section>

            {/* Services */}
            <section className="services py-5" id="services">
                <div className="container">
                    <h3 className="heading mb-5 heading-custom">Dịch vụ của chúng tôi</h3>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="our-services-wrapper mb-60">
                                <div className="services-inner">
                                    <div className="our-services-img">
                                        <img src="/assets/icons/makeup.svg" />
                                    </div>
                                    <div className="our-services-text">
                                        <h4>Makeup</h4>
                                        <p>Tú Linh Beauty - nơi tôn vinh vẻ đẹp tự nhiên và tinh tế của bạn trong mỗi dịp đặc biệt. Chúng tôi chuyên tư vấn và thực hiện trang điểm cô dâu và trang điểm cho các buổi tiệc, đảm bảo mang lại sự tự tin và rạng rỡ cho bạn trong mọi bức ảnh và khoảnh khắc đáng nhớ.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="our-services-wrapper mb-60">
                                <div className="services-inner">
                                    <div className="our-services-img">
                                        <img src="/assets/icons/nails.svg" />
                                    </div>
                                    <div className="our-services-text">
                                        <h4>Nails</h4>
                                        <p>Tú Linh Beauty - nơi bạn có thể trải nghiệm dịch vụ làm nails chất lượng và chuyên nghiệp nhất, chúng tôi cam kết mang đến cho bạn bộ móng đẹp hoàn hảo và tự tin. Kkhông chỉ tạo ra những thiết kế nails độc đáo và sáng tạo, mà còn chú trọng vào việc bảo vệ sức khỏe và làm đẹp cho bàn tay của bạn.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div className="our-services-wrapper mb-60">
                                <div className="services-inner">
                                    <div className="our-services-img">
                                        {/* <span className="fa fa-gavel"></span> */}

                                        <img src="/assets/icons/head-washing.svg" />
                                    </div>
                                    <div className="our-services-text">
                                        <h4>Gội Đầu</h4>
                                        <p>Tú Linh Beauty - nơi bạn có thể tận hưởng dịch vụ gội đầu tốt. Thư giãn trong không gian yên tĩnh và được phục vụ bởi đội ngũ chuyên nghiệp, chúng tôi cam kết mang đến cho bạn mái tóc sạch sẽ, mềm mại và tươi mới nhất.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* stats section */}
            <section className="stats-section-w3pvt" id="stats">
                <div className="py-5">
                    <div className="container py-lg-5">
                        <h3 className="heading mb-sm-5 mb-4">Our statistics</h3>
                        <div className="row text-center">
                            <div className="col-lg-3 col-6">
                                <div className="w3layouts_stats_left w3_counter_grid">
                                    <div className="stats-icon">
                                        <span className="fa fa-credit-card"></span>
                                    </div>
                                    <p className="counter text-black">1568</p>
                                    <p className="para-text-w3ls">Happy Couples</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="w3layouts_stats_left w3_counter_grid2">
                                    <div className="stats-icon">
                                        <span className="fa fa-users"></span>
                                    </div>
                                    <p className="counter">1900</p>
                                    <p className="para-text-w3ls">Married Couples</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 mt-lg-0 mt-4">
                                <div className="w3layouts_stats_left w3_counter_grid1">
                                    <div className="stats-icon">
                                        <span className="fa fa-dollar"></span>
                                    </div>
                                    <p className="counter">1422</p>
                                    <p className="para-text-w3ls">Registered</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 mt-lg-0 mt-4">
                                <div className="w3layouts_stats_left w3_counter_grid1">
                                    <div className="stats-icon">
                                        <span className="fa fa-users"></span>
                                    </div>
                                    <p className="counter">400</p>
                                    <p className="para-text-w3ls">Media Followers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </LayoutComponent>
    )
}

export default HomeComponent;