import React from "react";

function FooterComponent() {
    const phoneNumber = process.env.REACT_APP_PHONE_NUMBER_OWNER;
    const email = process.env.REACT_APP_EMAIL_OWNER;

    // socials
    const facebook = process.env.REACT_APP_FACEBOOK_LINK;
    const tiktok = process.env.REACT_APP_TIKTOK_LINK;
    const zalo = process.env.REACT_APP_ZALO_LINK;
    const messenger = process.env.REACT_APP_MESSENGER_LINK;
    const maps = process.env.REACT_APP_GOOGLE_MAPS_LINK;

    return (
        <>
            {/* subscription */}
            <section className="subscribe" id="subscribe">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5 d-flex subscribe-left p-lg-5 py-sm-5 py-4">
                            <div className="news-icon mr-3">
                                <span className="fa fa-paper-plane" aria-hidden="true"></span>
                            </div>
                            <div className="text">
                                <h3>Đăng ký nhận tin từ</h3>
                                <h3>Tú Linh Beauty</h3>
                            </div>
                        </div>
                        <div className="col-md-7 subscribe-right p-lg-5 py-sm-5 py-4">
                            <form id="subscription-form" action="#" method="post">
                                <input className="phoneNumber" type="tel" name="tel" placeholder="Nhập số điện thoại của bạn" required={true} />
                                <button className="btn1"><span className="fa fa-paper-plane" aria-hidden="true"></span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="footer-layer py-sm-5 pt-5 pb-3">
                    <div className="container py-md-3">
                        <div className="footer-grid_section text-center">
                            <div className="footer-title mb-3">
                                <a href="#"><span className="fa fa-gift"></span> Tú Linh Beauty</a>
                            </div>
                            <div className="footer-text">
                                <p>Bạn có thể xem qua các liên kết cũng như các mạng xã hội của chúng tôi.</p>
                            </div>
                            <ul className="social_section_1info">
                                <li className="mb-2 facebook"><a href={facebook} title="Facebook" target="_blank"><span className="fa fa-facebook"></span></a></li>
                                <li className="mb-2 social-icon">
                                    <a href={tiktok} title="TikTok" target="_blank">
                                        <img src="/assets/images/tiktok-icon.png" />
                                    </a>
                                </li>
                                <li className="mb-2 social-icon">
                                    <a href={zalo} title="Zalo" target="_blank">
                                        <img src="/assets/images/zalo-icon.png" />
                                    </a>
                                </li>

                                <li className="mb-2 social-icon">
                                    <a href={messenger} title="Messenger" target="_blank">
                                        <img src="/assets/images/messenger-icon.png" />
                                    </a>
                                </li>

                                <li className="google"><a href={maps} title="Google Maps" target="_blank"><span className="fa fa-map"></span></a></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </footer>

            <section className="copyright">
                <div className="container py-4">
                    <div className="row bottom">
                        <ul className="col-lg-6 links p-0">
                            <li><a href={`tel:${phoneNumber}`}><span className="fa fa-phone"></span> {phoneNumber}</a></li>
                            <li><a href={`mailto:${email}`}><span className="fa fa-envelope-open"></span> {email}</a></li>
                        </ul>
                        <div className="col-lg-6 copy-right p-0">
                            <p className="">© {new Date().getFullYear()} Tú Linh Beauty. Phát triển bởi
                                <a href="https://tulinh.beauty"> Tú Linh Beauty.</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <div className="move-top text-right">
                <a href="#home" className="move-top">
                    <span className="fa fa-angle-up  mb-3" aria-hidden="true"></span>
                </a>
            </div>
        </>
    );
}

export default FooterComponent;