import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';

const PREFIX_URL =
    'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';

class CustomSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIndex: false,
            showBullets: true,
            infinite: true,
            showThumbnails: true,
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: true,
            showGalleryPlayButton: true,
            showNav: true,
            isRTL: false,
            slideDuration: 450,
            slideInterval: 2000,
            slideOnThumbnailOver: false,
            thumbnailPosition: 'bottom',
            showVideo: {},
            useWindowKeyDown: true,
            images: []
        };

        console.log(props.assets);

        this.images = [
            {
                id: '111',
                thumbnail: `https://i3.ytimg.com/vi/sGvh-WJjK60/hqdefault.jpg`,
                original: `https://i3.ytimg.com/vi/sGvh-WJjK60/maxresdefault.jpg`,
                embedUrl:
                    'https://www.youtube.com/embed/sGvh-WJjK60?showinfo=0',
                description: 'Render custom slides (such as videos)',
                renderItem: this._renderVideo.bind(this),
            },
            {
                id: '222',
                thumbnail: `https://i3.ytimg.com/vi/tYGh2qnCl9I/hqdefault.jpg`,
                original: `https://i3.ytimg.com/vi/tYGh2qnCl9I/maxresdefault.jpg`,
                embedUrl:
                    'https://www.youtube.com/embed/tYGh2qnCl9I?showinfo=0',
                description: 'Render custom slides (such as videos)',
                renderItem: this._renderVideo.bind(this),
            },
            {
                id: '333',
                original: `${PREFIX_URL}1.jpg`,
                thumbnail: `${PREFIX_URL}1t.jpg`,
                originalClass: 'featured-slide',
                thumbnailClass: 'featured-thumb',
                description: 'Custom class for slides & thumbnails',
            },
        ];
    }

    _onImageClick(event) {
        console.debug(
            'clicked on image',
            event.target,
            'at index',
            this._imageGallery.getCurrentIndex()
        );
    }

    _onImageLoad(event) {
        console.debug('loaded image', event.target.src);
    }

    _onSlide(index) {
        if (this.images && this.images[index].embedUrl) {
            this.setState({ showGalleryPlayButton: false });
            this.setState({ showGalleryFullscreenButton: false });
        } else {
            this.setState({ showGalleryPlayButton: true });
            this.setState({ showGalleryFullscreenButton: true });
            this._resetVideo();
        }
        console.log('slid to index', index);
    }

    _onPause(index) {
        console.log('paused on index', index);
    }

    _onScreenChange(fullScreenElement) {
        console.log('isFullScreen?', !!fullScreenElement);
    }

    _onPlay(index) {
        console.log('playing from index', index);
    }

    _handleInputChange(state, event) {
        if (event.target.value > 0) {
            this.setState({ [state]: event.target.value });
        }
    }

    _handleCheckboxChange(state, event) {
        this.setState({ [state]: event.target.checked });
    }

    _handleThumbnailPositionChange(event) {
        this.setState({ thumbnailPosition: event.target.value });
    }

    _resetVideo() {
        this.setState({ showVideo: {} });

        if (this.state.showPlayButton) {
            this.setState({ showGalleryPlayButton: true });
        }

        if (this.state.showFullscreenButton) {
            this.setState({ showGalleryFullscreenButton: true });
        }
    }

    _toggleShowVideo(item) {
        let url = item.embedUrl;
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo,
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({ showGalleryPlayButton: false });
            }

            if (this.state.showFullscreenButton) {
                this.setState({ showGalleryFullscreenButton: false });
            }

            setTimeout(() => {
                let element = document.querySelector(`#video-player-${item.id}`);
                if (element) {
                    console.log('src: ', element.getAttribute('src'));
                    let newLink = `${element.getAttribute('src')}&autoplay=1`;
                    console.log('new link: ', newLink);
                    element.setAttribute('src', newLink);
                }
            }, 1000);
        }
    }

    _renderVideo(item) {
        return (
            <div>
                <div className="video-wrapper">
                    <iframe
                        id={`video-player-${item.id}`}
                        width="560"
                        height="315"
                        src={item.embedUrl}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        console.log('test 11111111')
    }

    componentDidMount() {
        if (this.images && this.images[0].embedUrl) {
            this.setState({ showGalleryPlayButton: false });
            this.setState({ showGalleryFullscreenButton: false });
        }
    }

    render() {
        return (
            <section className="app">
                <ImageGallery
                    ref={(i) => (this._imageGallery = i)}
                    items={this.images}
                    onClick={this._onImageClick.bind(this)}
                    onImageLoad={this._onImageLoad}
                    onSlide={this._onSlide.bind(this)}
                    onPause={this._onPause.bind(this)}
                    onScreenChange={this._onScreenChange.bind(this)}
                    onPlay={this._onPlay.bind(this)}
                    infinite={this.state.infinite}
                    showBullets={this.state.showBullets}
                    showFullscreenButton={
                        this.state.showFullscreenButton &&
                        this.state.showGalleryFullscreenButton
                    }
                    showPlayButton={
                        this.state.showPlayButton && this.state.showGalleryPlayButton
                    }
                    showThumbnails={this.state.showThumbnails}
                    showIndex={this.state.showIndex}
                    showNav={this.state.showNav}
                    isRTL={this.state.isRTL}
                    thumbnailPosition={this.state.thumbnailPosition}
                    slideDuration={parseInt(this.state.slideDuration)}
                    slideInterval={parseInt(this.state.slideInterval)}
                    slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                    additionalClass="app-image-gallery"
                    useWindowKeyDown={this.state.useWindowKeyDown}
                />
            </section>
        );
    }
}

export default CustomSlider;
